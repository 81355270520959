import { IMAGE_VARIANT_MAP, ASPECT_RATIO_CLASSES } from "../image";

const CONTENTFUL_API_DEFAULTS = {
  q: 75,
  w: 1014
};

export function contentfulImageUrlFor(url, paramOverrides = {}) {
  if (!url) {
    throw new Error("Asset is missing a url");
  }

  // default to `webp` except for pngs and svgs
  const fm = url.endsWith(".png") || url.endsWith(".svg") ? "" : "webp";

  const apiParams = { ...CONTENTFUL_API_DEFAULTS, fm, ...paramOverrides };

  const params = new URLSearchParams();

  if (url?.startsWith("//images.ctfassets.net")) {
    for (const key of Object.keys(apiParams)) {
      params.set(key, apiParams[key]);
    }
  }

  // this is an unfortunate necessity. For some reason the `size` property
  // is not available on the params object, so we spread it and check the length.
  if ([...params].length) {
    return `${url}?${params.toString()}`;
  } else {
    return url;
  }
}

export function buildSrc(asset, resizeParams, ignoreContentfulImgResize) {
  const url = asset?.fields?.file?.url;
  if (!url) {
    return null;
  }

  if (ignoreContentfulImgResize || !url?.startsWith("//images.ctfassets.net")) {
    return url;
  }

  return contentfulImageUrlFor(url, resizeParams);
}

// This function is used to generate the srcset attribute for an image
// It takes an array integers representing the widths of each image in (real) pixels
// and generates a srcset for each.
export function srcSetFromWidths(url, widths = [], paramOverrides = {}) {
  if (widths.length === 0 || !url) {
    return;
  }

  return widths
    .map((width) => `${contentfulImageUrlFor(url, { ...paramOverrides, w: width })} ${width}w`)
    .join(",\n");
}

// These values are pulled from the design tokens used in the
// tailwind config for @ObamaFoundation/of-design-system
const SCREENS = {
  sm: "320px",
  md: "680px",
  lg: "1200px",
  xl: "1920px"
};

function sizeForMinWidth(minWidth, size) {
  return `(min-width: ${minWidth}) ${size}`;
}

export function sizesForBreakpoints(sizes, breakpoints = SCREENS) {
  // If there are no sizes, or all sizes are 100vw, we don't need to specify
  if (!sizes) {
    return;
  }
  if (Object.keys(sizes).length === 0) {
    return;
  }
  if (Object.values(sizes).every((size) => !size || size === "100vw")) {
    return;
  }

  // We want to control the order here because the sizes are cascading
  // and we want to make sure the smallest size is last.
  const sizeAttr = ["xl", "lg", "md", "sm"].flatMap((size) => {
    const minWidth = breakpoints[size];
    const sizeForWidth = sizes[size];

    // Ignore missing values so we can respect the cascade
    // without needing to specify all sizes
    if (!minWidth || !sizeForWidth) {
      return [];
    }

    return sizeForMinWidth(minWidth, sizeForWidth);
  });

  // Add the default size (all screen sizes < sm)
  sizeAttr.push("100vw");

  return sizeAttr.join(", ");
}

export function altTextToDisplay(altText) {
  const placeholderAltValues = [
    "placeholder",
    "none",
    "alt text missing",
    "gray placeholder image for qa",
    "missing"
  ];
  if (altText && !placeholderAltValues.includes(altText.toLowerCase())) {
    return altText;
  } else {
    return "";
  }
}

export function getImageOrientation(asset) {
  const { height, width } = asset?.fields?.file?.details?.image || {};

  if (!height || !width) {
    return;
  }

  // if the difference between height and width is within 5%, treat as square
  return height > width
    ? (height - width) / width > 0.05
      ? "portrait"
      : "square"
    : (width - height) / height > 0.05
      ? "landscape"
      : "square";
}

export const getImageAspectRatio = (asset, aspectRatio) => {
  const imageDetails = asset?.fields?.file?.details?.image || {};
  const { width, height } = imageDetails;

  let mappedVariant = IMAGE_VARIANT_MAP[aspectRatio];
  let calculatedVariant = IMAGE_VARIANT_MAP["1:1"];
  if (width > height) {
    calculatedVariant = IMAGE_VARIANT_MAP["3:2"];
  } else if (width < height) {
    calculatedVariant = IMAGE_VARIANT_MAP["3:4"];
  }

  return mappedVariant || calculatedVariant;
};

export const getClosestAspectRatioClass = (
  width,
  height,
  validAspectRatios = ASPECT_RATIO_CLASSES
) => {
  const aspectRatio = width / height;

  /* store the closest aspect ratio found in ASPECT_RATIO_CLASSES. */
  let closestAspectRatio = null;

  /* store the smallest difference between the calculated aspect ratio and the aspect ratios in ASPECT_RATIO_CLASSES. */
  let minDifference = Infinity;

  for (const ratio in validAspectRatios) {
    if (Object.hasOwn(validAspectRatios, ratio)) {
      const ratioValueString = validAspectRatios[ratio];
      if (ratioValueString === "aspect-[auto]") {
        continue; // Skip the "auto" aspect ratio
      }
      const calcRatios = ratioValueString.split("/");
      const ratioWidth = Number(calcRatios[0].split("-")[1]);
      const ratioHeight = Number(calcRatios[1]);
      const ratioValue = ratioWidth / ratioHeight;

      /* calculates the absolute difference between the calculated aspect ratio and the aspect ratio of the given width and height. */
      const difference = Math.abs(aspectRatio - ratioValue);

      /* calculates the new smallest difference and the current aspect ratio, respectively. */
      if (difference < minDifference) {
        minDifference = difference;
        closestAspectRatio = validAspectRatios[ratio];
      }
    }
  }
  return closestAspectRatio;
};
