import { writable } from "svelte/store";

// Used to set the theme of the Global Navigation
export const heroThemeStore = writable("transparent");

//Used to store filter items
export const filterItemsStore = writable([]);

//Used to track whether the filter panel is open
export const filterListIsOpen = writable(false);
